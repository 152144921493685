import React, { useContext, useState, useEffect, useRef } from 'react';
import { useCryptoState } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient';
import moment from 'moment';
import InputEmoji from 'react-input-emoji';
import axios from 'axios';
import { baseUrl } from '../../utils/services'; // Ensure this path is correct

const ChatBox = () => {
  const { admin } = useCryptoState();
  const { currentChat, messages, isMessageLoading, sendTextMessage } = useContext(ChatContext);
  const { recipientUser } = useFetchRecipientUser(currentChat, admin);
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [coinInfo, setCoinInfo] = useState(null);
  const scroll = useRef();
  const [recipientAccounts, setRecipientAccounts] = useState([]);
  const [accountData, setAccountData] = useState({});

  useEffect(() => {
    const fetchRecipientAccounts = async () => {
      if (recipientUser) {
        try {
          const response = await axios.get(`${baseUrl}/auth/accounts/${recipientUser?.id}`);
          console.log('Recipient Accounts:', response.data); // Log account details
          const accounts = response.data;
  
          // Group accounts by type (bank, wallet)
          const categorizedAccounts = accounts.reduce((acc, account) => {
            const { type } = account;
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(account);
            return acc;
          }, {});
  
          setAccountData(categorizedAccounts);
  
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log('No accounts found for this user.');
            // Handle no accounts found (404)
            setAccountData(null); // or some message to indicate no accounts
          } else {
            console.error('Error fetching recipient accounts:', error);
          }
        }
      }
    };
  
    fetchRecipientAccounts();
  }, [recipientUser]);
  
  
  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  
  const [userAccDetails, setUserAccDetails] = useState(null);
  console.log(recipientUser);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const response = await axios.post(`${baseUrl}/auth/account-details/${recipientUser?.id}`, {
          status: "user", 
        });
        console.log("got response");
        console.log(response);
        setUserAccDetails(response.data); 
      } catch (error) {
        console.error('Error fetching user account details:', error);
      }
    };

    if (recipientUser?.id) {
      fetchAccountDetails();
    }
  }, [recipientUser]);

  useEffect(() => {
    const fetchCoinInfo = async () => {
      if (!messages || !messages[0]?.item_id) return; 
      try {
        const response = await axios.get(`${baseUrl}/chat/coins/${messages[0].item_id}`);
        setCoinInfo(response.data);
      } catch (error) {
        console.error('Error fetching coin info:', error);
      }
    };

    fetchCoinInfo();
  }, [messages]);

  const handleTerminateChat = async () => {
    try {
      await axios.put(`${baseUrl}/chat/terminate/${currentChat[0]?.id}`);
      alert('Chat terminated successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error terminating chat:', error);
    }
  };

  const handleCloseDeal = async () => {
    const cid = coinInfo?.cid;
    const buy_price = coinInfo?.buy_price;
    const chatId = currentChat[0]?.id;
    const members = JSON.parse(currentChat[0]?.members || '[]');
    const userId = members[0];  
    const adminId = members[1]; 
    const type = "sell";

    const requestBody = {
      cid,
      buy_price,
      chatId,
      userId,
      adminId,
      type
    };

    try {
      await axios.put(`${baseUrl}/chat/close-deal/${chatId}`, requestBody);
      alert('Deal closed successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error closing deal:', error);
    }
  };

  if (!recipientUser) {
    return (
      <p className="text-center w-full">
        No Conversation Selected Yet.....
      </p>
    );
  }

  if (isMessageLoading) {
    return (
      <p className="text-center w-full">
        Loading Chat.....
      </p>
    );
  }

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="chat-header font-bold text-xl py-2 px-4 border-b dark:border-gray-600">
        {recipientUser?.name}
      </div>
<div className="admin-account-details">
  <h2 style={{ color: '#333', fontSize: '1.5rem', fontWeight: 'bold' }}>
    User's Account Details
  </h2>
  {accountData ? (
    Object.keys(accountData).length > 0 ? (
      Object.keys(accountData).map((type) => (
        <div key={type}>
          <h3 style={{ fontSize: '1.2rem', color: '#333' }}>{type.toUpperCase()}</h3>
          <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
            {accountData[type].map((account) => (
              <li
                key={account.acc_id}
                style={{
                  marginBottom: '10px',
                  padding: '10px',
                  backgroundColor: '#f8f9fa',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                }}
              >
                <strong style={{ fontWeight: 'bold', color: '#0056b3' }}>
                  Name:
                </strong>{' '}
                {account.acc_name} <br />
                <strong style={{ fontWeight: 'bold', color: '#0056b3' }}>
                  Identification:
                </strong>{' '}
                {account.identification}
              </li>
            ))}
          </ul>
        </div>
      ))
    ) : (
      <p style={{ color: '#AA0000', fontSize: '1.2rem' }}>
        No accounts found for this user.
      </p>
    )
  ) : (
    <p style={{ color: '#AA0000', fontSize: '1.2rem' }}>
        No accounts found for this user.
    </p>
  )}
</div>

      {coinInfo && (
        <div className="coin-info p-4 bg-gray-800 text-white rounded-lg mb-4">
          <h3>User Account Information</h3>
          {userAccDetails && (
            <div className="user-account-details p-4 bg-gray-100 text-black rounded-lg mb-4">
              <h4>User Account Details:</h4>
              <p><strong>Type:</strong> {userAccDetails.type}</p>
              <p><strong>Account Name:</strong> {userAccDetails.acc_name}</p>
              <p><strong>Identification:</strong> {userAccDetails.identification}</p>
            </div>
          )}
          <h4 className="text-yellow-400 text-sm font-medium mb-1">Coin Information:</h4>
          <p><strong>Name:</strong> {coinInfo.name}</p>
          <p><strong>Only trade {coinInfo.name} here.</strong></p>
          <p>Thanks for your cooperation!</p>
          <div className="flex justify-end mt-4 space-x-2">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
              onClick={handleTerminateChat}
            >
              Terminate Chat
            </button>
            <button
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
              onClick={handleCloseDeal}
            >
              Close Deal
            </button>
          </div>
        </div>
      )}

      <div className="messages flex-col gap-4 mt-4">
        {Array.isArray(messages) && messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.senderId === String(admin.id) ? 'justify-end' : 'justify-start'} mb-3`}
          >
            <div
              className={`message p-3 rounded-lg max-w-xs ${message.senderId === String(admin.id) ? 'bg-blue-700 text-white' : 'bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-200'}`}
              ref={scroll}
            >
              {message.image && (
                <img
                  src={message.image}
                  alt="sent content"
                  className="sent-image max-w-full rounded-lg mb-2"
                />
              )}
              <span>{message.text}</span>
              <div className="text-sm text-right text-yellow-600 dark:text-yellow-500 mt-1">
                {moment(message.created_at).calendar()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-input flex flex-col gap-2 p-3 border-t dark:border-gray-600">
  <InputEmoji
    value={textMessage}
    onChange={setTextMessage}
    fontFamily='nunito'
    borderColor='rgba(72,112,223,0.2)'
    className="flex-grow max-w-full"
    placeholder="Type a message..."
  />
  <div className="flex items-center justify-between">
    <input
      type="file"
      accept="image/*"
      onChange={(e) => setImage(e.target.files[0])}
      className="file-input"
    />
<button
  className="send-btn bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-200 shadow-md"
  style={{
    touchAction: 'manipulation', // Optimizes for touch interactions
    width: '100%', // Ensures the button resizes well on mobile screens
    maxWidth: '200px', // Limit button width for mobile
  }}
  onClick={() =>
    sendTextMessage(
      coinInfo.cid,
      textMessage,
      String(admin.id),
      currentChat?.[0]?.id,
      image,
      setTextMessage
    )
  }
>
  Send
</button>

  </div>
</div>

    </div>
  );
};

export default ChatBox;
