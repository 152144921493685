// import React, { useContext, useState,useEffect } from 'react';
// import { ChatContext } from '../context/ChatContext';
// import { Container, Stack } from '@mui/material';
// import UserChat from '../components/chat/UserChat';
// import AuthContext, { useCryptoState } from '../context/AuthContext';
// import PotentialChats from '../components/chat/PotentialChats';
// import ChatBox from '../components/chat/ChatBox';
// import Header from '../components/Header/Header';
// import Sidebar from '../components/Sidebar/Sidebar';
// import { useLocation } from 'react-router-dom';

// const Chat = ({ toggleDarkMode, darkMode }) => {
//   const { admin } = useCryptoState(); 
//   const { userChats, isUserChatsLoading, userChatsError, updateCurrentChat } = useContext(ChatContext);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const location = useLocation();

//   const { data } = location.state || {};

//   const [selectedChatId, setSelectedChatId] = useState(data?.id || null);


//   useEffect(() => {
//     if (data) {
//       setSelectedChatId(data?.id || null);
//     }
//   }, [data]);
//     const handleChatClick = (chat) => {
//     updateCurrentChat(chat);
//     setSelectedChatId(chat[0]?.id);
//   };


//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };
//   const uniqueChats = userChats.reduce((acc, current) => {
//     const x = acc.find(item => item.id === current.id);
//     if (!x) {
//       return acc.concat([current]);
//     } else {
//       return acc;
//     }
//   }, []);
//   return (
//     <div className={`flex h-screen ${darkMode ? 'bg-gray-900 text-black' : 'bg-white text-gray-900'}`}>
//       <div className="flex flex-col flex-grow">
//         <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} toggleSidebar={toggleSidebar} />
//         <div className="flex flex-grow overflow-hidden">
//           <Sidebar isSidebarOpen={isSidebarOpen}  />
//           <Container className="flex-grow flex flex-col p-4 max-w-3xl mx-auto my-4">
//             <div className="flex-grow flex flex-col">
//               <PotentialChats />
//               {userChats?.length < 1 ? (
//   <div >
//             No available chats at the moment...
//           </div>
//       ) : (
//                 <div className="flex flex-grow overflow-hidden">
//                   <div className="w-1/3 p-2 overflow-y-auto bg-gray-300 dark:bg-gray-800 rounded-lg max-h-[calc(100vh-10rem)]">
//                     {isUserChatsLoading && <p className="text-gray-500">Loading chats ...</p>}
//                     {userChats?.map((chat, index) => {
//                       const chatArray = Array.isArray(chat) ? chat : [chat];
//                       return (
//                         <div key={index} onClick={() => updateCurrentChat(chatArray)} className="cursor-pointer">
//                           <UserChat chat={chatArray} user={admin} />
//                         </div>
//                       );
//                     })}
        
//                   </div> 
//                   <div className="flex-grow p-2 overflow-y-auto bg-gray-400 dark:bg-gray-800 rounded-lg max-h-[calc(100vh-10rem)]">
//                     <ChatBox />
//                   </div>
//                 </div>
//               )}
//             </div>
//           </Container>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Chat;



import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import { Container, Stack } from '@mui/material';
import UserChat from '../components/chat/UserChat';
import AuthContext, { useCryptoState } from '../context/AuthContext';
import PotentialChats from '../components/chat/PotentialChats';
import ChatBox from '../components/chat/ChatBox';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';

const Chat = ({ toggleDarkMode, darkMode }) => {
  const { admin } = useCryptoState(); 
  const { userChats, isUserChatsLoading, userChatsError, updateCurrentChat } = useContext(ChatContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { data } = location.state || {};
  const [selectedChatId, setSelectedChatId] = useState(data?.id || null);

  useEffect(() => {
    if (data) {
      setSelectedChatId(data?.id || null);
    }
  }, [data]);

  const handleChatClick = (chat) => {
    updateCurrentChat(chat);
    setSelectedChatId(chat[0]?.id);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const uniqueChats = userChats.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="flex flex-col flex-grow">
        <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} toggleSidebar={toggleSidebar} />
        <div className="flex flex-grow overflow-hidden">
          <Sidebar isSidebarOpen={isSidebarOpen} />
          <Container className="flex-grow flex flex-col p-4 max-w-3xl mx-auto my-4">
            <div className="flex-grow flex flex-col">
              <PotentialChats />
              {userChats?.length < 1 ? (
                <div>No available chats at the moment...</div>
              ) : (
                <div className="flex flex-grow overflow-hidden">
                  <div className="w-1/3 p-2 overflow-y-auto bg-gray-300 dark:bg-gray-800 rounded-lg max-h-[calc(100vh-10rem)]">
                    {isUserChatsLoading && <p className="text-gray-500">Loading chats ...</p>}
                    {userChats?.map((chat, index) => {
                      const chatArray = Array.isArray(chat) ? chat : [chat];
                      return (
                        <div 
                          key={index} 
                          onClick={() => handleChatClick(chatArray)} 
                          className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap"
                        >
                          <UserChat chat={chatArray} user={admin} />
                        </div>
                      );
                    })}
                  </div> 
                  <div className="flex-grow p-2 overflow-y-auto bg-gray-400 dark:bg-gray-800 rounded-lg max-h-[calc(100vh-10rem)]">
                    <ChatBox />
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Chat;
