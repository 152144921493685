import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./Pages/Dashboard";
import { AuthContextProvider, useCryptoState } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import Chat from "./Pages/Chat";
import Coins from "./Pages/Coins";
import Currency from "./Pages/Currency";
import Admins from "./Pages/Admins";
import Transaction from "./Pages/Transaction";
import ProfilePage from "./Pages/ProfilePage";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <AuthContextProvider>
      <AppContent toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
    </AuthContextProvider>
  );
}

const AppContent = ({ toggleDarkMode, darkMode }) => {
  const { admin, isLoggedIn } = useCryptoState();

  console.log("Is Logged In:", isLoggedIn);
  console.log(admin);

  return (
    <ChatContextProvider admin={admin}>
      <Router>
        <div className={darkMode ? "bg-gray-800" : "bg-white"}>
          <div className="mb-3">
            <Routes>
              <Route
                path="/login"
                element={
                  <div className="flex w-full h-screen">
                    <div className="w-full flex items-center justify-center">
                      <Login />
                    </div>
                  </div>
                }
              />
              <Route
                path="/"
                element={
                  admin ? (
                    <Coins
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              {/* <Route
                path="/dashboard"
                element={ admin?
                  <Dashboard
                    toggleDarkMode={toggleDarkMode}
                    darkMode={darkMode}
                    admin={admin}
                  />
                  :
                  <div className="flex w-full h-screen">
                    <div className="w-full flex items-center justify-center">
                      <Login />
                    </div>
                  </div>
                }
              /> */}
              <Route
                path="/message"
                element={
                  admin ? (
                    <Chat
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              <Route
                path="/coins"
                element={
                  admin ? (
                    <Coins
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              <Route
                path="/currency"
                element={
                  admin ? (
                    <Currency
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              <Route
                path="/admins"
                element={
                  admin ? (
                    <Admins
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              <Route
                path="/transaction"
                element={
                  admin ? (
                    <Transaction
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
              <Route
                path="/profile"
                element={
                  admin ? (
                    <ProfilePage
                      toggleDarkMode={toggleDarkMode}
                      darkMode={darkMode}
                      admin={admin}
                    />
                  ) : (
                    <div className="flex w-full h-screen">
                      <div className="w-full flex items-center justify-center">
                        <Login />
                      </div>
                    </div>
                  )
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </ChatContextProvider>
  );
};

export default App;
